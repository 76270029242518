import React from 'react';

export default function NoResult() {
	return (
		<div className="noresult">
			<h4>No Result</h4>
			<a href="https://gcox.dev/">return home</a>
		</div>
	);
}
